import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const themeOptions = {
	palette: {
		type: "light",
		primary: {
			main: "#e55e4c",
			dark: "#e55e4c",
			light: "#e67474"
		},
		secondary: {
			main: "#454551"
		},
		error: red,
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
	typography: {
		useNextVariants: true,
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: "none",
			},
		},
		MuiCard: {
			root: {
				borderRadius: 8,
			},
		},
		MuiFab: {
			root: {
				textTransform: "none",
			},
		},
	},
};

export const Theme = createMuiTheme({
	...themeOptions,
});

export const FooterTheme = createMuiTheme({
	...themeOptions,
	palette: {
		type: "dark",
	},
});
