import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Icon } from "@material-ui/core";
import AppleIcon from "../common/icons/AppleIcon";
import { ThumbUp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	themeText: {
		color: theme.palette.text.primary,
	},

	musicImage: {
		width: "362px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},

	musicImageWrapper: {
		position: "relative",
		display: "inline-block",
		"&::before": {
			content: '" "',
			position: "absolute",
			display: "block",
			height: "80%",
			left: 0,
			right: 0,
			margin: "0 180px -50px -2000px",
			bottom: 0,
			borderRadius: "300px",
			background: theme.palette.primary.main,
			transformOrigin: "right bottom",
			transform: "rotate(45deg)",
			zIndex: -1,
			opacity: 0.1,
		},

		"&::after": {
			content: '" "',
			position: "absolute",
			display: "block",
			height: 36,
			width: "80%",
			marginTop: 32,
			left: "10%",
			opacity: 0.75,
			background:
				"radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
		},

		[theme.breakpoints.down("sm")]: {
			display: "block",
			"&::after": {
				display: "none",
			},
			"&::before": {
				display: "none",
			},
		},
	},
}));

const Intro4 = () => {
	const classes = useStyles();

	return (
		<section className="section section-intro" id="intro4">
			<div className="container">
				<Grid container spacing={3} alignItems="center">
					<Grid item lg={6} md={6} sm={6} xs={12}>
						<div className={classes.musicImageWrapper}>
							<img
								className={classes.musicImage}
								src="/assets/images/device-2021-01-24-143048.png"
								alt="mobile-1"
							/>
						</div>
					</Grid>
					<Grid item lg={6} md={6} sm={6} xs={12}>
						<div>
							<p className="text-primary m-0 mb-4 text-20">Knit</p>
							<h1 className="font-normal text-44 mt-0">Hyper-local mesh messaging</h1>
							<p className="max-w-400 mb-8">
								No special hardware. No Internet connection. Seamlessly create a connected
								peer-based mesh network around you and text away.
							</p>
							{/*<Button*/}
							{/*  className={classes.themeText + " rounded px-12 text-black"}*/}
							{/*  variant="outlined"*/}
							{/*  color="primary"*/}
							{/*>*/}
							{/*  CLICK TO WATCH A VIDEO*/}
							{/*</Button>*/}

							<div className="mt-25 flex flex-wrap">
								<Button
									className="mr-6 px-6 mb-4 rounded py-3"
									variant="contained"
									color="primary"
									href="https://play.google.com/store/apps/details?id=app.getknit.knit"
								>
									<Icon fontSize="small" className="mr-3">
										android
									</Icon>
									Get Knit
								</Button>

								<Button
									className="mr-6 px-6 mb-4 rounded py-3"
									variant="contained"
									color="secondary"
									href="https://www.patreon.com/bePatron?u=49400189"
								>
									<ThumbUp fontSize="small" className="mr-3">

									</ThumbUp>
									Become a patron
								</Button>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</section>
	);
};

export default Intro4;
